<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title> LIMITES DE FIGURAS </template>
    <div class="mt-4 d-flex justify-content-center">
      <vs-button
        v-for="(item, i) in figures"
        :key="i"
        @click="handleOption(item)">
        {{ item.number }}
      </vs-button>
    </div>
    <b-modal v-model="limitModal" @ok.prevent="saveLimits" size="lg">
      <Limits :currencies="limitsFigure" @update-limits="updateLimits" />
    </b-modal>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Limits from "./Limits.vue";

export default {
  components: { Limits },
  props: {
    figures: {
      type: Array,
      default: () => {
        return [{ number: 1, multiplier: 2 }];
      },
    },
    currencies: {
      type: Array,
      default: () => {
        return [];
      },
    },
    game: {
      type: Object,
      default: () => {
        return {};
      },
    },
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modal: false,
      limitModal: false,
      limitsFigure: [],
      newLimits: [],
    };
  },
  computed: {
    ...mapGetters({
      getLimitsFormatted: "operators/getLimitsFormatted",
    }),
  },
  methods: {
    handleOption(figure) {
      const limits = this.currencies.map((el) => {
        const limitFigure = el.figures.find((e) => e.figure === figure.number);

        return {
          currency: el.currency,
          short: el.short,
          minBet: limitFigure ? limitFigure.minBet : 0,
          maxBet: limitFigure ? limitFigure.maxBet : 0,
          figure: figure.number,
        };
      });

      this.limitsFigure = limits;
      this.limitModal = true;
    },
    updateLimits(limits) {
      this.newLimits = limits;
    },
    async saveLimits() {
      const limits = this.getLimitsFormatted(
        this.limitsFigure,
        this.newLimits,
        this.game
      );

      const limit = this.currencies.map((el) => {
        const currentFigure = limits.find((l) => l.currency === el.currency);

        const currentFigures = el.figures.filter(
          (e) => e.figure !== currentFigure.figure
        );

        currentFigures.push(currentFigure);

        return {
          ...el,
          figure: currentFigures,
        };
      });

      const updatedLimits = await this.updateOperatorLimits({
        operator: this.operator.uuid,
        game: this.game.uuid,
        limits: limit,
      });

      if (!updatedLimits)
        this.$swal.fire("Error actualizando limites", "", "error");

      this.$swal.fire("Limites actualizados", "", "success");
      this.closeModa();
    },
    closeModa() {
      this.modal = false;
      this.limitModal = false;
      // this.$emit("closeModal");
    },
    ...mapActions({
      updateOperatorLimits: "operators/updateOperatorLimits",
    }),
  },
};
</script>
